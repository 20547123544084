<template>
  <div>
    
    <!-- <v-row class="mt-2">
      <v-col cols="12" md="6">
       <v-text-field
          v-model="form.acountingName"
          outlined
          dense
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-btn
         
          class="btn-add"
          @click="reload"
          :disabled="!form.acountingName"
        >
          Asignar Todas 
        </v-btn>
      </v-col>
    </v-row> -->
    <block v-if="loading"/>

    <v-card flat class="pa-4 bar mb-7">
      <div class="d-flex justify-end align-center">
        <v-btn
          class="btnsave mr-1"
          small
          @click="saveServicesAllocation"
          v-if="$store.getters['rolesUser/getCustomRole'](186)"
        >
          <v-icon>
            mdi-content-save
          </v-icon>
        
        </v-btn>
      </div>
    </v-card>
    <AccountingAssign
      v-if="$store.getters['rolesUser/getCustomRole'](184)"
      v-model="form.id_account"
      :win="win"
      :assignAll="true"
      @accountChanged="accountChanged"
      @assignAllAccounts="assignAllAccounts"
    >
    </AccountingAssign>
             
    <div>
      <!-- Table 4 start - Services -->
      <VersatileFilter
        entity="lista"
        :filterEndpoint="$API.services.getAllServicesFiltered"
        :search="search"
        @emptyFilter="getAllServices"
        @filtered="filterHandlerServices"
        :columnfilterEndpoint="$API.services.getAllServicesFilteredColumns"
        :columnsSearch="columsConfig"
        :disableDates="true"
        v-if="$store.getters['rolesUser/getCustomRole'](178)"
      >
        <DxDataGrid ref="accountServices" :data-source="services" key-expr="CodeService" :show-row-lines="showRowLines"
          @exporting="onExporting" :show-borders="showBorders" :row-alternation-enabled="rowAlternationEnabled"
          @editing-start="onEditingStart"
          :scrolling="{ useNative: true }"    
          :column-auto-width="true"
          @row-updating="validateData"
        >
        <DxEditing
            
            :allow-updating="true"
              
            mode="cell"
          />
          <DxScrolling row-rendering-mode="virtual" />
          <DxExport :enabled="$store.getters['rolesUser/getCustomRole'](187)" />
          <DxPaging :page-size="10" />
          <DxColumn
            data-field="CodeService"
            caption="Código del Servicio"
            :allow-updating="false"
            
          ></DxColumn>
          <DxColumn
            data-field="NameService"
            caption="Nombre del Servicio"
            :allow-updating="false"
            
          ></DxColumn>
          <DxColumn
            data-field="Name"
            :allow-updating="false"
            caption="Nombre a Facturar"
          ></DxColumn>
          <DxColumn
            data-field="TypeService"
            caption="Tipo de Servicio"
            :allow-updating="false"
          >
             <DxLookup
              :data-source="typeService"
              display-expr="Name"
              :allow-updating="false"
              value-expr="ID"
            /> 
          </DxColumn>
          <DxColumn :allow-updating="false"  data-field="familyN" caption="Familia"></DxColumn>
          <DxColumn :allow-updating="false"  data-field="categoryN" caption="Categoría"></DxColumn>
          <DxColumn :allow-updating="false"  data-field="subcategoryN" caption="Subcategoría"></DxColumn>
          <DxColumn
            :width="80"
            cell-template="show-template"
            caption=""
            v-if="$store.getters['rolesUser/getCustomRole'](185)"
          ></DxColumn>
          <DxColumn data-field="account_code"
            :allow-updating="true"                    
            caption="Cuenta de Ventas a Asociar"></DxColumn>
          
          
          <template #show-template="{data}">
        <div>
        <a href="#" class="mx-1" @click="servicesSelected = data; showModel =true">
          <v-icon>mdi-magnify

          </v-icon>
        </a>
    </div>
  </template>
  <template #setFamilies="{data}">
    <div>
      <span >
        {{ setFamilies(data.data) }}
      </span>
    </div>
  </template>
  <template #setCategories="{data}">
    <div>
      <span >
        {{ setCategories(data.data) }}
      </span>
    </div>
  </template>
  <template #setSubcategories="{data}">
    <div>
      <span >
        {{ setSubcategories(data.data) }}
      </span>
    </div>
  </template>
          
            
          </DxDataGrid>
        </VersatileFilter>
        <!-- Table 4 end - Services -->
      </div>

      <!-- Show  Modal -->
      <v-dialog v-model="showModel" width="60%">
        <v-card>
          <AccountingItemForm :win="win"
          v-on:sendSelection="reaciveSelection"
          />
        </v-card>
      </v-dialog>

      
      <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
            
          
  </div>
</template>

<script>
import VersatileFilter from "@/components/VersatileFilter";
import AccountingItemForm from "./AccountingItemForm";
import { DxDataGrid, DxColumn, DxScrolling, DxPaging, DxExport, DxEditing,DxLookup } from 'devextreme-vue/data-grid';
import AccountingAssign from "./AccountingAssign.vue";
import Block from "@/components/Block.vue";
import Alerts from "@/components/Alerts";
import { mapState } from "vuex";
import { printToExcel } from "@/helpers/printToexcel";
export default {
  name: "AllocationServices",
  props: ['win','datafilter'],
  components: {
    Block,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxScrolling,
    DxPaging,
    AccountingItemForm,
    DxLookup,
    Alerts,
    DxExport,
    VersatileFilter,
    AccountingAssign
  },

  data() {
    return {
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      tab: null,
      panel: 0,
      form: {
        id_account: null
      },
      servicesSelected: false,
      services: {},
     
      search: {
        filter: null,
      },
      valid: null,
      editService: null,
      serviceToDelete: null,
      deleteDialog: false,
      showModel: false,
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      typebusiness: null,
      typeService: [
    {
      ID: "PLT",
      Name: "Test de Laboratorio",
    },
    {
      ID: "OTR",
      Name: "Otro",
    },
    {
      ID: "VAC",
      Name: "Vacuna",
    },
    {
      ID: "MED",
      Name: "Medicamento",
    },
  ] ,
  loading: false,
      columsConfig: [
        {
          name:  'iinv.CodeService',
          title: "Codigo del Servicio",
          value: '',
          type: "text",
        },
        {
          name: 'iinv.NameService',
          title: "Nombre del Servicio",
          value: '',
          type: "text",
        },
        {
          name:  'iinv.Name',
          title: "Nombre a Facturar",
          value: '',
          type: "text",
        },
        {
          name:  'iinv.TypeService',
          title: "Tipo de Servicio",
          value: '',
          type: "select",
          options: [{
            value: "PLT",
            name: "Test de Laboratorio",
          },
          {
            value: "OTR",
            name: "Otro",
          },
          {
            value: "VAC",
            name: "Vacuna",
          },
          {
            value: "MED",
            name: "Medicamento",
          }],
        },
        {
          name: "family",
          title: "Familia",
          value: '',
          type: "select",
          options: [],
        },
        {
          name: "category",
          title: "Categoría",
          value: '',
          type: "select",
          options: [],
        },
        {
          name: "subcategory",
          title: "Subcategoría",
          value: '',
          type: "select",
          options: [],
        },
      ],
      
      filterServices: {},
      showOrderVacMed: false,
      optFilterOrder: 1,
      families : [],
      categories : [],
      subcategories : [],
      accountList: [],
    };
  },
 
  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
  },
  mounted() {
    
   
    this.$store.dispatch("rolesUser/getAllRoles");
    this.typebusiness =
      JSON.parse(localStorage.getItem("user")).businesstype || "";
    this.filterServices.business = JSON.parse(
      localStorage.getItem("user")
    ).businessid;
    this.filterServices.branch = JSON.parse(
      localStorage.getItem("user")
    ).branch;
    this.search.branch = this.filterServices.branch;
    this.search.business = this.filterServices.business;
    this.getAllServices();
    this.getFamilies()
    this.getCategories()
    this.getSubs()
  },
  methods: {
    accountChanged( e ) {
      this.form.id_account = e
    },
    reaciveSelection(data){
      
      this.showModel = false;
      this.servicesSelected.data.newId_account = data.id_account;
      this.servicesSelected.data.account_code = data.account_code;
      this.servicesSelected.data.newAccount_Code = data.account_code;
      this.$refs.accountServices.instance.saveEditData();
    },
    getFamilies() {
      this.$API.itemtypes.getFamilies().then(response => {
        this.families = response
        // console.log("response famili", response);
        this.families.forEach(family => {
          this.columsConfig[4].options.push({ name: family.name, value: family.id })
        });
      })
    },
    getCategories() {
      this.$API.itemtypes.getCategories()
        .then(response => {
          this.categories = response
          this.categories.forEach(category => {
            this.columsConfig[5].options.push({ name: category.name, value: category.id })
          });
        })
    },
    validateData(e) {
      console.log(e);
      if(e.newData.account_code){
        const found = this.accountList.find( current => current.account_code === e.newData.account_code );

        if(found){
          e.newData.newId_account = found.id_account;
          e.newData.account_code = found.account_code;
        }
        else{
          e.cancel = true;
          this.showAlert("warning", "Error", "La cuenta ingresada no existe");
        }
      }
    },
    getSubs() {
      this.$API.itemtypes.getSubs()
        .then(response => {
          this.subcategories = response
          this.subcategories.forEach(subcategory => {
            this.columsConfig[6].options.push({ name: subcategory.name, value: subcategory.id })
          });
        })
    },
    assignAllAccounts(){
      for(const index in this.services) {

        const found = this.accountList.find( current => current.id_account === this.form.id_account );

        if(found){
          this.services[index].newId_account = found.id_account;
          this.services[index].account_code = found.account_code;
        }
      }
      this.$refs.accountServices.instance.refresh()
      this.showAlert("success", "Exito", 'Se ha actualizado la tabla asignando la cuenta seleccionada a los productos. Revise la columna "Cuenta de Ventas a Asociar" para verificar y haga click el boton verde para guardar');
    },
    getAllAccounts(dataToFormat){
      this.$API.branchaccountcatalog.getAssignableAccounts(  )
        .then( response => {
            this.accountList = response.data;
            this.matchAccounts(dataToFormat);
        } ).catch( error => {
            console.log( error );
        } )
    },
    matchAccounts(dataToFormat){
      for(const index in dataToFormat) {
        const { id_sales_account } = dataToFormat[index];

        const found = this.accountList.find( current => current.id_account === id_sales_account );

        if(found){
          dataToFormat[index].account_code = found.account_code;
        }

      }

      this.$refs.accountServices.instance.saveEditData()
    },
   
    setFamilies(data){
      let name = ''
     this.families.forEach(family => {
       if(family.id == data.family){
         name = family.name
       }
     });
      return name
    },
    saveServicesAllocation(){
      try {
        this.loading = true;
        this.$refs.accountServices.instance.refresh();
        this.$API.branchaccountcatalog.assignAccountsServices([...this.services])
          .then( response => {
            this.showAlert("success", "Exito", response.msg);
            this.loading = false;
            this.getAllServices();
          }).catch(error => {
            this.showAlert("error", "Error", error.message);
            this.loading = false;
          })
      } catch (error) {
        console.log(error);
      }
    },
    setCategories(data){
      let name = ''
     this.categories.forEach(category => {
       if(category.id == data.category){
         name = category.name
       }
     });
      return name
    },
    setSubcategories(data){
      let name = ''
     this.subcategories.forEach(subcategory => {
       if(subcategory.id == data.subcategory){
         name = subcategory.name
       }
     });
      return name
    },
    onExporting(e) {
      printToExcel(e, "Servicios");
    },
    reload() {
      this.getAllServices();
      setTimeout(() => {
        window.scrollTo(0, this.$store.getters.getScrollTop);
        //alert(this.$store.getters.getScrollTop)
      }, 300);
    },
    closeAlert() {
      this.alert.show = false;
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deleteservice") {
        this.deleteService(this.alert.data.ID_Service);
        //(this.alert.data);
      }
    },
    showAlert(type, header, body, options = null, data = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
      this.alert.data = data != null ? data : null;
    },
    confirmdeleteService(data) {
      this.showAlert(
        "question",
        "¿Está seguro de eliminar el servicio?",
        "Esta acción no se puede deshacer",
        "deleteservice",
        data
      );
    },
    deleteService(data) {
      this.$API.services
        .deleteService(data)
        .then(() => {
          this.showAlert(
            "success",
            "Servicio eliminado",
            "El servicio ha sido eliminado correctamente"
          );
          this.closeDialog();
        })
        .catch(() => {
          this.showAlert(
            "danger",
            "Error al eliminar el servicio",
            "El servicio no ha sido eliminado"
          );
        });
    },
    getAllServices() {
      this.filterServices.withInnerjoins = true;
      this.$API.services
        .getAllServicesHistory(this.filterServices)
        .then(async(response) => {
           await this.getAllAccounts(response);
          
          response.forEach((element) => {
            if (element.Iva) {
              element.PriceCalc = element.Price * 1.13;
            } else {
              element.PriceCalc = element.Price;
            }
          });
          this.services = response;
          
          
          
        });
    },
    closeDialog() {
      this.serviceToDelete = null;
      this.editService = false;
      this.showModel = false;
      this.deleteDialog = false;
      this.getAllServices(this.filterServices);
    },

    updateOrderOfServices(data) {
      this.$API.services
        .updateOrderOfServices(data)
        .then((response) => {
          this.showOrderVacMed = !this.showOrderVacMed;
          this.showAlert("success", "Servicios actualizados", response.message);
        })
        .catch((err) => {
          this.showAlert("danger", "Error actualizando servicios", err.message);
        });
    },
    onEditingStart(e) {
      if (e.column.dataField != "account_code") {
        e.cancel = true;
      }
    },

    async UpdateRowCan() {
      this.servicesCan.sort((a, b) => a.Order - b.Order);
    },

    async UpdateRowFel() {
      this.servicesFel.sort((a, b) => a.Order - b.Order);
    },

    async UpdateRowAll() {
      this.servicesAll.sort((a, b) => a.Order - b.Order);
    },

    filterHandlerServiceCan(response) {
      this.servicesCan = response;
    },

    filterHandlerServicesFel(response) {
      this.servicesFan = response;
    },

    filterHandlerServicesAll(response) {
      this.servicesAll = response;
    },

    filterHandlerServices(response) {
      response.forEach((element) => {
        if (element.Iva) {
          element.PriceCalc = element.Price * 1.13;
        } else {
          element.PriceCalc = element.Price;
        }
      });
      response.forEach(element => {
         const found = this.accountList.find( current => current.id_account === element.id_sales_account );

        if(found)
          element.account_code = found.account_code;
      });
      this.services = response;
      // this.servicesCan = response.filter((r) => r.species == "Canino");
      // this.servicesCan.sort((a, b) => a.Order - b.Order);
      // this.servicesFel = response.filter((r) => r.species == "Felino");
      // this.servicesFel.sort((a, b) => a.Order - b.Order);
      // this.servicesAll = response.filter(
      //   (r) =>
      //     r.species == "Todas las especies" ||
      //     r.species == "Canino" ||
      //     r.species == "Felino"
      // );
      // this.servicesAll.sort((a, b) => a.Order - b.Order);
    },
  },
};
</script>

<style scoped>

.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}
.close-icon {
  color: red;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}
.centered {
  display: flex;
  justify-content: center;
}
</style>

